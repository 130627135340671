/**
 * @license
 *
 * Font Family: Satoshi
 * Designed by: Deni Anggara
 * URL: https://www.fontshare.com/fonts/satoshi
 * © 2021 Indian Type Foundry
 * 
 */

@font-face {
  font-family: 'Satoshi-Variable';
  src: url('./assets/fonts/Satoshi-Variable.woff2') format('woff2');
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

:root {
  --default-border-radius: 0.5rem;
  --default-disabled-color: #f2f2f2;
  --default-disabled-bg-color: #f2f2f2;
  --default-intellect-logo-color: #028df9;
  /* Mui Colors */
  --default-error-color: #ec7979;

  /* Color variables for APP */
  --chip-green2-color: #00ca9a;
  --chip-green2-bg-color: hsla(166, 100%, 40%, 0.07);
  --chip-green-color: #31a93e;
  --chip-green-bg-color: rgba(49, 169, 62, 0.15);
  --chip-yellow-color: #dd8500;
  --chip-yellow-bg-color: rgba(247, 206, 143, 0.15);
  --chip-red-color: #e96a6a;
  --chip-red-bg-color: rgba(233, 106, 106, 0.15);
  --chip-blue-color: #4dccff;
  --chip-blue-bg-color: rgba(77, 204, 255, 0.15);
  --chip-grey-color: #888;
  --chip-grey-bg-color: #f0f0f0;
  --chip-purple-color: #94aefa;
  --chip-purple-bg-color: rgba(148, 174, 250, 0.15);

  /* Scrollbar */
  --scroll-thumb: #d0d5dd;
  --scroll-thumb-hover: #98a2b3;
  --scroll-track: #f7f7f7;

  /* Tabs */
  /* --tab-selected-bg-color: rgba(174, 174, 174, 0.15); */
  --tab-selected-bg-color: #f2f2f2;
  --tab-selected-color: '#000';

  --toggle-switch-bg-color: var(--chip-grey-bg-color);
  --toggle-switch-label-color: var(--chip-grey-color);
  --toggle-switch-thumb-bg-color: white;
  --toggle-switch-thumb-color: #000;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family:
    'Satoshi-Variable',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiListItemButton-root.Mui-selected {
  background-color: #f2f1ef;
}

.MuiListItemButton-root.Mui-selected p {
  color: #707484;
  font-weight: bold;
}

label.MuiInputLabel-root {
  font-size: 1rem;
  font-weight: 600;
  color: #3e445b;
  margin-bottom: 0.5rem;
}

.MuiOutlinedInput-root.MuiInputBase-root.Mui-disabled {
  background-color: var(--default-disabled-bg-color);
}

#root + iframe {
  display: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.ql-editor img {
  width: 200px !important;
}

.ql-editor #logo {
  width: 10000px !important;
}

.ql-snow .ql-editor img {
  max-width: 1000%;
}

.txt-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.highlist-tableview-row {
  color: var(--chip-red-color) !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--scroll-track);
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 8px;
  border-radius: 6px;
  background: var(--scroll-thumb);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-thumb-hover);
}

/* RBC Calendar */
.rbc-event {
  padding: 0px !important;
  border-radius: 5px !important;
  border: none !important;
  background-color: transparent !important;
  z-index: 2;
  position: relative;
}

.rbc-background-event {
  padding: 0px !important;
  border-radius: 5px !important;
  border: none !important;
  background-color: transparent !important;
  opacity: 1 !important;
}
.rbc-time-view {
  border: none !important;
}

.rbc-event-label {
  display: none !important;
}

.rbc-header .rbc-button-link {
  font-weight: 500 !important;
}

.rbc-header {
  border: none !important;
  height: 60px !important;
}

.rbc-allday-cell {
  display: none;
}

.rbc-today {
  background: none !important;
}
.rbc-header.rbc-today .rbc-custom-day-header-day {
  color: #1570ef !important;
}

.rbc-header.rbc-today .rbc-custom-day-header-date {
  color: #fff;
  border-radius: 50%;
  background-color: #1570ef !important;
}

/* .rbc-events-container {
  width: 100% !important;
} */
